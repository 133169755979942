import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { User } from '@/types/api'
import axios from '@/utils/axios'
import { useEnvStore } from '@/stores/envStore'
import { useRouter } from 'vue-router'

export const useUserStore = defineStore('user', () => {
  const user = ref<User | null>(null)
  const lastRestaurantUpdate = ref<Date | null>(null) // Track last update time
  const envStore = useEnvStore()
  const remainingCredits = ref<number>(0)
  const previousCreditsRemaining = ref<number | null>(null)
  const userConfirmedWarning = ref(false)
  const showCreditWarning = ref(false)
  const showNoMoreCreditDialog = ref(false)
  const router = useRouter()
  // const canUserGenerate = computed(() => {
  //   return remainingCredits.value > 0
  // });

  function validateUserCanGenerate(creditsNeeded = 1) {
    if (remainingCredits.value < creditsNeeded) {
      console.log("SHOWING NO MORE CREDIT DIALOG")
      useUserStore().showNoMoreCreditDialog = true;
      return false;
    }
    return true;
  }

  // function checkCanUserGenerateDish({ creditsNeeded = 1 }) {
  //   if (remainingCredits.value >= creditsNeeded) {
  //     showNoMoreCreditDialog.value = true
  //     return false
  //   }
  //   return true
  // }

  function saveToLocalStorage() {
    const data = {
      // user: user.value,
      lastRestaurantUpdate: lastRestaurantUpdate.value,
      remainingCredits: remainingCredits.value,
      previousCreditsRemaining: previousCreditsRemaining.value,
      userConfirmedWarning: userConfirmedWarning.value,
      showNoMoreCreditDialog: showNoMoreCreditDialog.value
    }
    localStorage.setItem('restaurantInfoStore', JSON.stringify(data))
  }

  function loadFromLocalStorage() {
    const stored = localStorage.getItem('restaurantInfoStore')
    if (stored) {
      const data = JSON.parse(stored)
      // user.value = data.user ? transformUserData(data.user) : null
      lastRestaurantUpdate.value = data.lastRestaurantUpdate ? new Date(data.lastRestaurantUpdate) : null
      remainingCredits.value = data.remainingCredits
      previousCreditsRemaining.value = data.previousCreditsRemaining
      userConfirmedWarning.value = data.userConfirmedWarning
      showNoMoreCreditDialog.value = data.showNoMoreCreditDialog
    }
  }

  async function initUser() {
    loadFromLocalStorage()
    const serverUser = await getUser()
    if (serverUser) {
      user.value = serverUser
      // Update credit-related variables from server data
      if (serverUser.restaurants && serverUser.restaurants.length > 0) {
        const restaurant = serverUser.restaurants[0]
        const currentCredits = restaurant.total_credits - restaurant.credits_used
        remainingCredits.value = currentCredits
        previousCreditsRemaining.value = currentCredits

        // Reset warning states when initializing
        userConfirmedWarning.value = false
        showCreditWarning.value = false
        // showNoMoreCreditDialog.value = currentCredits <= 0
      }
      saveToLocalStorage()
    }
  }

  async function getUser(): Promise<User | null> {
    if (user.value) return user.value
    try {
      const response = await axios.get(envStore.apiUrl + '/me')
      const userData = response.data as User
      console.log("data from server: ", userData)
      return transformUserData(userData)
    } catch (error) {
      console.log("error", error)
      return null
    }
  }

  async function refreshUser() {
    try {
      const response = await axios.get(envStore.apiUrl + '/me')
      const userData = response.data as User
      user.value = transformUserData(userData)
      saveToLocalStorage()
    } catch (error: any) {
      if (error.response?.status === 401) {
        user.value = null
        localStorage.removeItem('restaurantInfoStore')
        router.push({ name: 'login' })
      }
      console.log("error", error)
    }
  }

  function updateCredits(totalCredits: number, creditsUsed: number) {
    const currentCredits = totalCredits - creditsUsed

    if (currentCredits <= 0) {
      showNoMoreCreditDialog.value = true
    } else if (
      !userConfirmedWarning.value &&
      (previousCreditsRemaining.value === null || previousCreditsRemaining.value > 20) &&
      currentCredits <= 20
    ) {
      showCreditWarning.value = true
    }
    remainingCredits.value = currentCredits

    // Update stored values
    if (user.value?.restaurants) {
      user.value.restaurants[0].total_credits = totalCredits
      user.value.restaurants[0].credits_used = creditsUsed
    }
    previousCreditsRemaining.value = currentCredits
    saveToLocalStorage()
  }

  function confirmCreditWarning() {
    userConfirmedWarning.value = true
    showCreditWarning.value = false
    saveToLocalStorage()
  }

  function confirmNoMoreCreditDialog() {
    showNoMoreCreditDialog.value = false
    saveToLocalStorage()
  }

  function transformUserData(userData: User): User {
    console.log("transforming user data")
    return {
      ...userData,
      created_at: new Date(userData.created_at),
      updated_at: new Date(userData.updated_at),
      email_verified_at: userData.email_verified_at ? new Date(userData.email_verified_at) : undefined,
      two_factor_confirmed_at: userData.two_factor_confirmed_at
        ? new Date(userData.two_factor_confirmed_at)
        : undefined,
    }
  }

  async function updateRestaurantInfo(forceUpdate = false) {
    // console.log("updating Restaurant Info")
    const now = new Date();
    if (!forceUpdate && lastRestaurantUpdate.value) {
      const minutesSinceLastUpdate = (now.getTime() - lastRestaurantUpdate.value.getTime()) / 60000;
      // console.log("Minutes since last update: ", minutesSinceLastUpdate);
      if (minutesSinceLastUpdate < 10) {
        // Data is fresh, no need to fetch again
        return;
      }
    }

    try {
      if (user.value && user.value.restaurants.length > 0) {
        // console.log("Fetching restaurant info");
        const restaurantId = user.value.restaurants[0].id;
        const response = await axios.get(`${envStore.apiUrl}/restaurants/${restaurantId}`);
        const restaurantData = response.data;

        // Update the credits and other necessary fields
        user.value.restaurants[0].total_credits = restaurantData.total_credits;
        user.value.restaurants[0].credits_used = restaurantData.credits_used;

        // Update the last successful update timestamp
        lastRestaurantUpdate.value = new Date();
      }
    } catch (error) {
      console.error('Failed to update restaurant information:', error);
    }
    if (lastRestaurantUpdate.value) {
      saveToLocalStorage()
    }
    // console.log("DONE");
  }

  return {
    user,
    initUser,
    getUser,
    updateRestaurantInfo,
    updateCredits,
    confirmCreditWarning,
    confirmNoMoreCreditDialog,
    showCreditWarning,
    remainingCredits,
    showNoMoreCreditDialog,
    validateUserCanGenerate,
    refreshUser,
  }
})